import { css } from '@emotion/css';

export const poster = css`
  position: relative;
  margin-top: 1.5em;
  transition: transform .75s ease 0s, opacity .75s ease 0s;

  :hover {
    transform: scale(1.025);
  }

  h5 {
    position: absolute;
    bottom: 0; left: 0; right: 0;
    margin: 0;
    padding: 0 .25em;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba(18, 47, 71, 0.6);
    color: #ffffff;
    text-shadow: 0 0 .5em black;
    line-height: 1;
    max-height: 2em;
  }
`;

export const image = css`
  height: 4em;
  box-shadow: .05em .05em .1em 0 rgba(0, 0, 0, .25);
  opacity: 0.6;
`;

export const avatar = css`
  position: absolute;
  top: 0.25em; left: 0.25em; width: 2em; height: 2em;

  img {
    width: 100%;
    height: 100%;
    box-shadow: 0.1em 0.1em 1em 0 black;
    border-radius: 100%;
    background-color: white;
  }
`;

export const timestamp = css`
  /* position: absolute;
  top: 0.25em; right: 0.25em; */
  display: inline-block;
`;
