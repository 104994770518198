import Img from 'gatsby-image';
import * as _ from 'lodash';
import * as React from 'react';
import { PageContext } from '../templates/post';
import * as css from './ArticlePoster.css';

export interface PostShortProps {
  post: PageContext;
}

export const ArticlePoster: React.FunctionComponent<PostShortProps> = ({ post }) => {
  return (
    <div className={css.poster}>
      {post.frontmatter.image && (
        <Img className={css.image} alt={`${post.frontmatter.title} cover image`}
          fluid={post.frontmatter.image.childImageSharp.fluid}/>
      )}
      <div className={css.avatar}>
        <img title={post.frontmatter.author.id} style={{display: "inline-block"}} alt={`${post.frontmatter.title} cover image`}
          src={post.frontmatter.author.avatar.children[0].fixed.src}/>
      </div>
      <h5 title={post.frontmatter.title}>
        {/* <div className={css.timestamp}>
          {post.frontmatter.date_month} {post.frontmatter.date_day}:&nbsp;
        </div> */}
        {post.frontmatter.title}
      </h5>
    </div>
  );
};

export default ArticlePoster;
